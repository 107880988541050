import { Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { debounceTime } from 'rxjs';

/**
 * This method transform a signal into an debounced signal which only set its value if the timeout is completed
 * @param input signal to apply the debounce time
 * @param timeOutMs timeout to emit the change in milliseconds
 * @returns the new debounced signal
 */
export const debouncedSignal = <T>(
  input: Signal<T>,
  timeOutMs = 350,
): Signal<T> =>
  toSignal(toObservable(input).pipe(debounceTime(timeOutMs)), {
    initialValue: input(),
  });
